// app/javascript/trix-editor-overrides.js
window.addEventListener("trix-file-accept", function(event) {
  event.preventDefault()
  alert("File attachment not supported!")
})

var Trix = require("trix");

/* what the newly created button does */
Trix.config.textAttributes.red = {
  style: { color: "red" },
  parser: function(element) {
    return element.style.color === "red"
  },
  inheritable: true
}

Trix.config.textAttributes.green = {
  style: { color: "green" },
  parser: function(element) {
    return element.style.color === "green"
  },
  inheritable: true
}


Trix.config.textAttributes.hilight = {
  style: { 'background-color': 'yellow' },
  parser: function(element) {
    return element.style.backgroundColor === "yellow"
  },
  inheritable: true
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
  var buttonHTML = '<button type="button" class="red trix-button" data-trix-attribute="red">RED</button>'
  buttonHTML += '<button type="button" class="trix-button" style="color:green !important" data-trix-attribute="green">GREEN</button>'
  buttonHTML += '<button type="button" class="yellow trix-button" data-trix-attribute="hilight">Hilight</button>'
  event.target.toolbarElement.querySelector(".trix-button-group").
    insertAdjacentHTML("beforeend", buttonHTML)
})
